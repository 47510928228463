











import Vue from "vue";
import Component from "vue-class-component";
import {ModelSync} from "vue-property-decorator";
import _startCase from "lodash.startcase";

// eslint-disable-next-line no-unused-vars
enum DateIntervalUnit {
    // eslint-disable-next-line no-unused-vars
    Hour = 'hour',
    // eslint-disable-next-line no-unused-vars
    Day = 'day',
    // eslint-disable-next-line no-unused-vars
    Weeks = 'week',
    // eslint-disable-next-line no-unused-vars
    Months = 'month',
    // eslint-disable-next-line no-unused-vars
    Years = 'year'
}

@Component
export default class PhpTimeoutPicker extends Vue {

    units: { value: DateIntervalUnit, name: string }[] =
        Object.values(DateIntervalUnit).map(unit => {
            return {
                value: unit,
                name: _startCase(unit) + '(s)'
            }
        });

    @ModelSync('modelValue', 'update:modelValue', {type: String, required: true})
    timeout!: string;

    get number(): number {
        return parseInt(this.timeoutParts[0]);
    }

    set number(val: number) {
        this.timeoutParts = [val.toString(), this.unitCode];
    }


    get unitCode(): DateIntervalUnit {
        return this.timeoutParts[1] as DateIntervalUnit;
    }

    set unitCode(val: DateIntervalUnit) {
        this.timeoutParts = [this.number.toString(), val];
    }


    get timeoutParts(): string[] {
        return this.timeout.split(" ");
    }

    set timeoutParts(parts: string[]) {
        this.timeout = parts.join(' ');
    }
}
