




































import {Component, Vue} from "vue-property-decorator";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import StatPicker from "@/components/pb/StatPicker.vue";
import PhpTimeoutPicker from "@/components/PhpTimeoutPicker.vue";

@Component({
    components: {PhpTimeoutPicker, StatPicker}
})
export default class Login extends Vue {
    email: string = "";

    password: string = "";
    showPw: boolean = false;
    showError: boolean = false;
    errorMessage: string = "???";
    timeout = "3 hour";

    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance
    }

    get googleLoginLink(): string {
        return `${MyPbxToolsApi.BASE_URL}/api/general/v1.0/google/login.php?timeout=${this.timeout}`;
    }

    async loginAsync() {

        const api = MyPbxToolsApi.instance;
        await api.doLoginAsync(this.email, this.password, this.timeout);

        const dest: string = '' + this.$route.query.redirect ?? '';
        await this.$router.push(dest);
    }

    login() {
        this.loginAsync().catch((err: any) => {
            console.error(err);
            this.showError = true;
            this.errorMessage = err + '';
        });
    }
}
